@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.d-box a {
  color: #2167d9;
  text-decoration: none;
}

.d-box:hover a {
  color: #fff;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  margin-right: 10px;
  border-radius: 6px;
  outline: 0;
  background: #ccc;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background: gray;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background: gray;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background: gray;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

.btn-arrow {
  width: 213px;
  height: 50px;
}

@media screen and (min-width: 120px) and (max-width: 576px) {
  .btn-arrow {
    width: 150px;
    height: 40px;
    background-size: contain;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .btn-arrow {
    width: 150px;
    height: 40px;
    background-size: contain;
  }
}

@media screen and (min-width: 768px) {
  .btn-arrow {
    width: 213px;
    height: 50px;
    background-size: contain;
  }
}

@media screen and (min-width: 1150px) {
  .arrow-btn-box {
    width: 850px;
    margin: auto;
  }
}
.pointer-events-none {
  pointer-events: none;
}
.opacity-50 {
  opacity: 50;
}
.react-calendar {
  max-width: 400px !important;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 23rem !important;
  margin-left: 1rem !important;
}

.react-calendar__tile--active {
  background-color: #007bff !important;
  color: #fff !important;
}

.react-calendar__tile--now {
  background-color: #f0f0f0 !important;
  color: #333 !important;
}

.react-calendar_month-viewdays_day--weekend {
  color: rgb(15, 14, 14) !important;
}

.react-calendar_month-viewdays_day--weekend,
.react-calendar_month-viewdays_day--weekend abbr {
  color: rgb(22, 21, 21) !important;
}

.react-calendar_month-viewdays_day,
.react-calendar_month-viewweekdays_weekday abbr {
  font-size: 1rem !important;
}

.react-calendar_navigation_label {
  font-size: 20px !important;
}

.react-calendar_month-viewdays_day:hover {
  color: white;
  background-color: black;
}

.react-calendar_month-viewdays_day,
.react-calendar_month-viewweekdays_weekday abbr {
  font-size: 16px;
}

.react-calendar_navigation_prev,
.react-calendar_navigation_next {
  font-size: 24px;
}

.react-calendar_month-viewdays_day {
  padding: 1rem !important;
}

.react-calendar_month-viewweekdays_weekday {
  border-bottom: none !important;
}

.calendar-container {
  background-color: #030916;
  min-height: 35rem;
  width: 30rem;
  padding: 3rem;
  padding-top: 1rem;
}
@media (max-width: 632px) {
  .calendar-container {
    width: auto;
  }
  .responsiveDiv {
    padding: 0;
  }
}
@media (max-width: 982px) {
  .flexCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
  }
  .responsiveDiv {
    padding: 1rem;
    padding-top: 10px;
    width: 100%;
  }
  .form-flex {
    padding: 0;
  }
}

.list-dics {
  list-style-type: disc;
  margin-left: 20px;
}

:root {
  --loader-width: 250px;
  --loader-dot-size: 20px;
}

.loader-container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  height: var(--loader-dot-size);
  width: var(--loader-width);
  position: relative;
  margin: auto;
}

.loader--dot {
  animation: loader 3s ease-in-out infinite;
  height: var(--loader-dot-size);
  width: var(--loader-dot-size);
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}

.loader--dot:nth-child(1) {
  background-color: #8cc759;
  animation-delay: 0.5s;
}

.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}

.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}

.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}

.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}

.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}

.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}

.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation: loading-text 3s infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(calc(var(--loader-width) - var(--loader-dot-size)));
  }

  65% {
    transform: translateX(calc(var(--loader-width) - var(--loader-dot-size)));
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {
  0% {
    content: "Loading";
  }

  25% {
    content: "Loading.";
  }

  50% {
    content: "Loading..";
  }

  75% {
    content: "Loading...";
  }
}
#cover-spin {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(1px);
  z-index: 9999;
  display: block;
}

/* @-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  transform: translate(-50%, -50%); 
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
} */

@media (max-width: 632px) {
  .responsive-style {
    width: 100% !important;
    margin-top: 0.75rem !important;
  }
}

@media (max-width: 750px) {
  .bookingheading {
    padding-top: 30px;
  }
  .small {
    display: none;
  }
}
@media (max-width: 769px) {
  .small {
    display: none;
  }
  .margin {
    margin-top: 10px;
  }
}
